.linea-tiempo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
}

.hexagon-container {
  width: 25%; /* Esto hace que cada hexágono ocupe el 25% del ancho de su contenedor */
  text-align: center;
}

.hexagon {
  width: 50px;
  height: 40px;
  background-color: transparent;
  position: relative;
  margin: 0 auto 20px;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}

.color-name {
  text-align: center;
}

.hexagon.blue {
  background-color: #5c7cfa;
}

.hexagon.pink {
  background-color: #F06595;
}

.hexagon.yellow {
  background-color: #FCC419;
}

.hexagon.green {
  background-color: #20c997;
}

html, body {
  height: 100%;
  margin: 0;
}

.estado-cuenta {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.datos {
  flex-grow: 1;
  margin-bottom: 20px;
}

.datos table {
  width: 80%;
  border-collapse: collapse;
  table-layout: fixed; /* Esto asegura que la tabla ocupe el 100% del ancho */
}

.datos td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  width: 50%; /* Ajusta según necesites */
}

.datos td:last-child {
  text-align: right;
}

.imagen-container {
  position: relative;
  width: 100px;
  height: 100px;
}

.imagen-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sin-imagen {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
  color: #888;
  font-size: 24px;
  border-radius: 5%;
}

.sin-imagen:hover {
  background-color: #e0e0e0;
}
